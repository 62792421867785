//For QA
// export const API_URL = "http://qa.power-recruit.net";

export const USA_VENDORS = ["Beeline", "Vectorvms", "Dotstaff", "Covendis"];
export const CAN_VENDORS = ["Flextrack"];
//For Production
export const API_URL = "https://powerrecruit.net";

export const VMSExpenseAPI= API_URL + "/api/job/getVendorpercentage"
export const ClientFeeAPI= API_URL +  "/api/job/getClientFee"

// export const API_URL = "http://192.168.43.69:8080"

//refreshtoken
export const REFRESHTOKEN_URL = API_URL + "/api/auth/request/token";
// export const RELOADtOKEN_URL = API_URL + "/api/auth/request/token"

// Added this code for  POW-102. Khushi. April 10 2023.. Code Start /
//automation api
export const AUTOMATION_CHECK = API_URL + "/api/automation/check ";
export const AUTOMATION_START = API_URL + "/api/automation/start";
export const USERCOUNT_DETAILS = API_URL + "/api/automation/usercountDetails";
export const UPDATE_CANDIDATE_UPLOADS = API_URL + "/api/automation/updatecandidateUpload?updatecandidateUpload=";
export const UPDATE_TIME = API_URL + "/api/automation/updateTime?time=";
export const UPDATE_JOB_UPLOADS = API_URL + "/api/automation/updateJobUpload?jobUpload=";
export const UPDATE_DAILY = API_URL + "/api/automation/updateRunType?runType=";
export const UPDATE_WEEKLY_VALUES = API_URL + "/api/automation/updateWeekly?weekly=";
// Khushi. April 10 2023. Code End /
// Added this code for  POW-102. Khushi. April 15 2023.. Code Start /
export const AUTOMATION_UPDATE = API_URL + "/api/automation/update ";
// Added this code for  POW-102. Anand. August 09 2023.. Code Start /
export const AUTOMATION_STOP = API_URL + "/api/automation/stop ";
export const AUTOMATION_STATUS = API_URL + "/api/automation/status ";

// Added this code for  POW-102. Anand. August 09 2023.. Code End /
// Khushi. April 15 2023. Code End /
// Added this code for  POW-102. Khushi. May 17 2023.. Code Start /
export const AUTOMATION_SSE = API_URL + "/api/automation/ ";
// Khushi. May 17 2023. Code End /
//dev
export const LOGIN_URL = API_URL + "/api/auth";
export const PAGEASSIGN_URL = API_URL + "/api/auth/get/page/permission";
export const FORGOT_URL = API_URL + "/api/auth/forgot";
//business units
export const BUSINESSUNITLIST_URL = API_URL + "/api/business-unit/search";
export const BUSINESSUNITCREATE_URL = API_URL + "/api/business-unit/create";
export const BUSINESSUNITEDIT_URL = API_URL + "/api/business-unit/edit";
export const BUSINESSSTATUS_URL = API_URL + "/api/business-unit/active";

/*Added this code for POW-547. Istiyak. 07th August.*/
export const BUSINESSUNITDASHBOARD_URL = API_URL + "/api/business-unit/user/search";

//function
export const FUNCTIONS_GET_URL = API_URL + "/api/auth/functions/search";

// profile business
export const PROFILE_BUSINESS_URL = API_URL + "/api/auth/business/plan";
// admin sort
//candidate job status
export const CANDIDATEJOBLIST_URL = API_URL + "/api/cs/search";
export const CANDIDATEJOBCREATE_URL = API_URL + "/api/cs/create";
export const CANDIDATEJOBEDIT_URL = API_URL + "/api/cs/edit";
export const CANDIDATEJOBSTATUS_URL = API_URL + "/api/cs/active";

//vendors details 
export const VENDOR_DETAILS =API_URL+ "/api/manager-details/getVendorDetails";

//client job status
export const CLIENTJOBLIST_URL = API_URL + "/api/cjs/search";
export const CLIENTJOBCREATE_URL = API_URL + "/api/cjs/create";
export const CLIENTJOBEDIT_URL = API_URL + "/api/cjs/edit";
export const CLIENTJOBSTATUS_URL = API_URL + "/api/cjs/active";
// Added this code for  POW-20. Prasanna. August 25 2022.. Code Start
//Manager details
export const MANAGERDETAILSLIST_URL = API_URL + "/api/manager-details/search";
export const MANAGERDETAILSCREATE_URL = API_URL + "/api/manager-details/create";
export const MANAGERDETAILSSEARCH_URL = API_URL + "/api/manager-details/search";
export const MANAGERDETAILSEDIT_URL = API_URL + "/api/manager-details/edit";
export const MANAGERDETAILSSTATUS_URL = API_URL + "/api/manager-details/search";
// Prasanna. August 25 2022. Code End
//workauthorization st
export const WORKAUTHORIZATIONLIST_URL = API_URL + "/api/wa/search";
export const WORKAUTHORIZATIONCREATE_URL = API_URL + "/api/wa/create";
export const WORKAUTHORIZATIONEDIT_URL = API_URL + "/api/wa/edit";
export const WORKAUTHORIZATIONSTATUS_URL = API_URL + "/api/wa/active";
//chatbot
export const CHATBOTLIST_URL = API_URL + "/api/cb/search";
export const CHATBOTCREATE_URL = API_URL + "/api/cb/create";
export const CHATBOTEDIT_URL = API_URL + "/api/cb/edit";
export const CHATBOTSTATUS_URL = API_URL + "/api/cb/active";
//sourcename
export const SOURCENAMESLIST_URL = API_URL + "/api/sn/search";
export const SOURCENAMESCREATE_URL = API_URL + "/api/sn/create";
export const SOURCENAMESEDIT_URL = API_URL + "/api/sn/edit";
export const SOURCENAMESSTATUS_URL = API_URL + "/api/sn/active";

//employment types
export const EMPLOYMENTTYPESLIST_URL =
  API_URL + "/api/job/employment/getEmploymentDetails";
export const EMPLOYMENTTYPESCREATE_URL =
  API_URL + "/api/job/employment/add/employmentType";
export const EMPLOYMENTTYPESEDIT_URL =
  API_URL + "/api/job/employment/update/employmentType";
export const EMPLOYMENTTYPESSTATUS_URL =
  API_URL + "/api/job/employment/update/status";
export const EMPLOYMENTTYPESNAMELIST_URL =
  API_URL + "/api/job/employment/getEmploymentName";

//manageuser
export const MANAGEUSERLIST_URL = API_URL + "/api/auth/user/search";
export const MANAGEUSERSTATUS_URL = API_URL + "/api/auth/user/active";
export const MANAGEUSERCREATE_URL = API_URL + "/api/auth/user/register";
export const MANAGEUSEREDIT_URL = API_URL + "/api/auth/user/edit";
export const MANAGEUSEREDITBYID_URL = API_URL + "/api/auth/single/user/search";


// managesecurityrole
export const MANAGESECURITYCREATE_URL =
API_URL + "/api/auth/add/page/permission";
export const MANAGESECURITYEDIT_URL =
API_URL + "/api/auth/edit/page/permission";
export const PAGELIST_URL = API_URL + "/api/auth/pages/list";
export const PERMISSION_URL = API_URL + "/api/auth/get/role/permission";
export const MANAGESECURITYROLESEARH_URL =
  API_URL + "/api/auth/user/role/search";
export const ROLELIST_URL = API_URL  + "/api/auth/roles/list";
export const DELETEROLE_URL = API_URL  + "/api/auth/delete/role";

//recruiter dashboard
//upload job
// export const UPLOADFILE_URL = API_URL + "/api/job/description/upload"
export const UPLOADFILE_URL = API_URL + "/api/job/dx/description/upload";
export const GOTOJOBDETAILS_URL = API_URL + "/api/job/details/redirect";
/*Added this code for  POW-515. Dharmateja. June 10 2023.. Code Start*/
export const GOTOMANAGEJOBREDIRECT_URL = API_URL + "/api/job/details/redirect/notsubmitcand";
/*Dharmateja. June 10 2023.. Code End*/
//match history
export const MATCHHISTORYSEARCH_URL =
  API_URL + "/api/results/match/history/search";
// export const MATCHRESULTS_URL = API_URL + "/api/job/view/matching/candidates"
export const MATCHRESULTS_URL =
  API_URL + "/api/job/dx/match/history/redirect/results";
//chatbot history
export const CHATBOTSEARCH_URL = API_URL + "/api/results/chatbot/search";
export const VIEWCHATBOT_URL = API_URL + "/api/results/chatbot/history";
export const BOTLIST_URL = API_URL + "/api/cb/activetypes";

//upload job details
export const UPLOADJOBDETAILS_URL = API_URL + "/api/job/description/update";
// export const UPLOADJOBDETAILS_URL = API_URL + "/api/job/dx/description/update"
export const UPLOADSEARCH_URL = API_URL + "/api/job/search/candidates";
export const UPLOADWORKAUTH_URL = API_URL + "/api/wa/activestatuses";
export const ATTACHMENTEDITURL = API_URL + "/api/job/dx/candidates/attachment";
export const UPLOADCLIENTJOB_URL = API_URL + "/api/cjs/activestatuses";
export const UPLOADGRABER_URL = API_URL + "/api/job/grabber/info ";
/* Added this code for  POW-431. Prasanna. November 23 2022.. Code Start */
export const UPLOADHISTORY_URL = API_URL +"/api/job/manual/jobsinfo";
/* Prasanna. November 23 2022. Code End */
// export const GRABERSEND_URL =API_URL + "/api/job/dx/grabber/upload?grabberFilePath=https://power-recruit.s3-us-east-2.amazonaws.com/JD/2020-09-28/Covendis/55341/1601279674.docx"
export const GRABERSEND_URL = API_URL + "/api/job/dx/grabber/upload";

//candidate search
// export const RESUMELINK_URL = API_URL + '/api/job/candidate'
export const RESUMELINK_URL = API_URL + "/api/job/dx/candidate/initiate/resume";
// export const RESUMEVIEWLINK_URL = API_URL + '/api/job/dx/candidate/resume/data'
export const RESUMEVIEWLINK_URL = API_URL + "/api/job/dx/jobboard/view/resume";
export const DICERESUMEVIEWLINK_URL = API_URL + "/api/job/dice/resume"
// export const JOBSEARCH_URL = API_URL + '/api/job/search/candidates'
export const INITIATESEARCH_URL =
  API_URL + "/api/job/dx/candidate/initiate/search";
// export const JOBSEARCH_URL = API_URL + '/api/job/dx/candidate/search/results'
export const JOBSEARCH_URL = API_URL + "/api/job/search/candidates";
// export const RUNMATCHING_URL = API_URL + '/api/job/run/matching/candidates'
export const RUNMATCHING_URL = API_URL + "/api/job/run/matching";
// export const UPLOADSOVREN_URL = API_URL + '/api/job/upload/candidates'
export const UPLOADSOVREN_URL =
  API_URL + "/api/job/dx/upload/candidates/other/sources";
export const UPLOADSOVRENSELECTED_URL =
API_URL + "/api/job/upload/candidates/dice";
export const JOBDESCRIPTION_URL = API_URL + "/api/job/description/info";

//match results
export const FILTERBY_URL = API_URL + "/api/job/run/matching/sort";
// export const VIEWRESUMES_URL = API_URL + '/api/results/match/resume'
// export const VIEWRESUMES_URL = API_URL + '/api/job/dx/matched/resume'
export const VIEWRESUMES_URL = API_URL + "/api/job/dx/matched/resume";
export const SENDBOT_URL = API_URL + "/api/results/sendbot";
export const SENDEMAIL_URL = API_URL + "/api/results/chatbot/send/mail";
export const SENDJOBEMAIL_URL = API_URL + "/api/results/network/send/mail";
export const SENDSMS_URL = API_URL + "/api/results/chatbot/send/sms";
export const GETJOBGRABBER_URL = API_URL + "/api/grabber/run/old"; //get jobgrabber
export const SENDCLIENTFORMATTEDRESUME_URL =
  API_URL + "/api/results/update/resume";
export const SOURCETYPE_URL = API_URL + "/api/sn/activesources";
export const CLIENTRESUMEFORMAT_URL = API_URL + "/api/results/resumeformat";
export const MIGRATE_URL = API_URL + "/api/job/dx/run/migrate/candidates";
export const JOBDETAILSKIPSEARCH_URL = API_URL + "/api/job/dx/skip/search";
export const MATCHSUBMIT_URL = API_URL + "/api/candidate/status/submit";
export const BACKTO_CANDIDATE_URL = API_URL + "/api/job/redirect/back";

// Manage job
export const MANAGEJOBSEARCH_URL = API_URL + "/api/candidate/job/search";
export const MANAGEJOBLIST_URL = API_URL + "/api/candidate/list";
export const MANAGEJOBDETAILS_URL = API_URL + "/api/candidate/job/details";
export const DICEJOBPOST_URL = API_URL + "/api/job/postJobOnDice";
// Manage Candidate
export const MANAGECANDIDATESEARCH_URL = API_URL + "/api/candidate/search";
export const MANAGECANDIDATESTATUS_URL = API_URL + "/api/cs/activestatuses";
export const MANAGECANDIDATEJOBLIST_URL = API_URL + "/api/candidate/job/list";
export const MANAGECANDIDATESKILLSET_URL = API_URL + "/api/candidate/details";
export const MANAGECANDIDATEDETAILS_URL = API_URL + "/api/candidate/details";
export const MANAGECANDIDATEVIEWSMS_URL = API_URL + "/api/results/sms/history";
export const MANAGECANDIDATEVIEWEMAIL_URL =
  API_URL + "/api/results/email/history";
export const MANAGECANDIDATEUPDATE_URL =
  API_URL + "/api/candidate/details/update";
export const CREATEPOSITIONHISTORY_URL =
  API_URL + "/api/candidate/emphistory/create";
export const CREATESKILLSET_URL = API_URL + "/api/candidate/skillset/create";
/* Added this code for  POW-377. Prasanna. November 28 2023.. Code Start */
export const CREATECANDIDATEPREF_URL = API_URL + "/api/candidate/candidatepref/create";
/* Prasanna. November 28 2023. Code End */
export const VIEWCONTACTINFO_URL =
  API_URL + "/api/candidate/communication/history";
//Email Attachment
export const SENDATTACHMENT_URL =
  API_URL + "/file/uploadMultipleFiles";

// Manage consultant

export const MANAGECONSULTANTSEARCH_URL =
  API_URL + "/api/candidate/to/consultant/search";
export const MANAGECONSULTANTSTATUS_URL =
  API_URL + "/api/candidate/to/consultant/active-inactive";
export const MANAGECONSULTANTUPDATE_URL =
  API_URL + "/api/candidate/to/consultant/update";
export const MANAGECONSULTANTADD_URL =
  API_URL + "/api/candidate/consultant/create";
//Manage Consultant Details
export const MANAGECONSULTANTDetails_URL =
  API_URL + "/api/candidate/to/consultant/getConsultantInfoById";
//Manage Consultant Update Personal Details
export const MANAGECONSULTANTPERSONALDERAILS_URL =
  API_URL +
  "/api/candidate/to/consultant/updatePersonalDetailsByConsultantInfoId";
//Manage Consultant Update Skills Details
export const MANAGECONSULTANTSkillsDERAILS_URL =
  API_URL + "/api/candidate/to/consultant/updateSkillsByConsultantInfoId";
//Manage Consultant Update Employment Details
export const MANAGECONSULTANTEMPLOYMENTDETAILS_URL =
  API_URL +
  "/api/candidate/to/consultant/updateEmploymentDetailsByConsultantInfoId";
//Manage Consultant Project Details
export const MANAGECONSULTANTPROJECTDETAILS_URL =
  API_URL +
  "/api/candidate/to/consultant/updateProjectDetailsByConsultantInfoId";
//Manage Consultant Project Timeline Details
export const MANAGECONSULTANTPROJECTTIMELINEDETAILS_URL =
  API_URL +
  "/api/candidate/to/consultant/updateProjectTimelineByConsultantInfoId";
//Hourly Rates And Fees Details
export const MANAGECONSULTANTHOURLYRATEANDFEESDETAILS_URL =
  API_URL +
  "/api/candidate/to/consultant/updateHourlyRatesAndFeesByConsultantInfoId";
//Prior Projects Details
export const MANAGECONSULTANTPRIORPROJECTSDETAILS_URL =
  API_URL +
  "/api/candidate/to/consultant/updatePriorProjectsDetailsByConsultantInfoId";
//Weekly Occurring Numbers  Details
export const MANAGECONSULTANTWEEKLOCCURRINGNUMBERDETAILS_URL =
  API_URL +
  "/api/candidate/to/consultant/updateWeeklyOccurringNumbersByConsultantInfoId";
//Prior Projects Add Details
export const MANAGECONSULTANTPRIORPROJECTSADDDETAILS_URL =
  API_URL +
  "/api/candidate/to/consultant/createPriorProjectsDetailsByConsultantInfoId";
//Weekly Occurring Numbers Add Details
export const MANAGECONSULTANTWEEKLOCCURRINGNUMBERADDDETAILS_URL =
  API_URL +
  "/api/candidate/to/consultant/createWeeklyOccurringNumbersByConsultantInfoId";
//Weekly Occurring Numbers Get Details
export const MANAGECONSULTANTWEEKLOCCURRINGNUMBERGETDETAILS_URL =
  API_URL + "/api/candidate/to/consultant/getWeeklyOccurringNumbers";
//Manager Add Details
export const MANAGECONSULTANTMANAGERDETAILS_URL =
  API_URL +
  "/api/candidate/to/consultant/createManagerDetailsByConsultantInfoId";
//Manager Update Details
export const MANAGECONSULTANTMANAGERUPDATEDETAILS_URL =
  API_URL +
  "/api/candidate/to/consultant/updateManagerDetailsByConsultantInfoId";
//Manager Delete Details
export const MANAGECONSULTANTMANAGERDELETEDETAILS_URL =
  API_URL + "/api/candidate/to/consultant/deleteManagerDetailsById";

// teamlead
// Manage client
export const MANAGECLIENTSEARCH_URL = API_URL + "/api/job/client/search";
export const MANAGECLIENTCREATE_URL = API_URL + "/api/job/client/create";
export const MANAGECLIENTEDIT_URL = API_URL + "/api/job/client/edit";
/* Added this code for  POW-464. Prasanna. February 02 2023.. Code Start */
export const MANAGECLIENTDELETE_URL = API_URL + "/api/job/client/deleteById";
/* Prasanna. February 02 2023. Code End */

// Manage Vendor
export const MANAGEVENDORSEARCH_URL = API_URL + "/api/job/vendor/search";
export const MANAGEVENDORCREATE_URL = API_URL + "/api/job/vendor/create";
export const MANAGEVENDOREDIT_URL = API_URL + "/api/job/vendor/edit";

/* Added this code for  POW-521. Prasanna. August 07 2023.. Code Start */
// Manage VMS Passwords
export const MANAGEVMSPASSWORDSSEARCH_URL = API_URL + "/api/job/vmspassword/search";
export const MANAGEVMSPASSWORDSCREATE_URL = API_URL + "/api/job/vmspassword/create";
export const MANAGEVMSPASSWORDSEDIT_URL= API_URL + "/api/job/vmspassword/edit";
export const MANAGEVMSPASSWORDSDELETE_URL = API_URL + "/api/job/vmspassword/deleteById";
/* Prasanna. August 07 2023. Code End */

// Manage Recruiter Network
export const MANAGERECRUITERNETWORKSEARCH_URL =
  API_URL + "/api/job/network/search";
export const MANAGERECRUITERNETWORKCREATE_URL =
  API_URL + "/api/job/network/create";
export const MANAGERECRUITERNETWORKEDIT_URL = API_URL + "/api/job/network/edit";
/* Added this code for  POW-472. Dharmateja.  January 31 2023.. Code Start */
export const MANAGERECRUITERNETWORKDELETE_URL = API_URL + "/api/job/network/deleteById";
/* Dharmateja. January 31 2023. Code End */

//live
// export const LOGIN_URL =API_URL+"api/auth"
// export const FORGOT_URL = API_URL+"api/auth/forgot"

// //job status for local testing
// export const JOBLIST_URL = "http://localhost:8110" + "/api/jobstatuses/search"
// export const JOBCREATE_URL = "http://localhost:8110" + "/api/jobstatuses/create"
// export const JOBEDIT_URL = "http://localhost:8110" + "/api/jobstatuses/edit"
// export const JOBSTATUS_URL = "http://localhost:8110" + "/api/jobstatuses/active"
// export const UPLOADJOBSTATUS_URL = "http://localhost:8110" + '/api/jobstatuses/activestatuses'

//job status
export const JOBLIST_URL = API_URL + "/api/jobstatuses/search";
export const JOBCREATE_URL = API_URL + "/api/jobstatuses/create";
export const JOBEDIT_URL = API_URL + "/api/jobstatuses/edit";
export const JOBSTATUS_URL = API_URL + "/api/jobstatuses/active";
export const UPLOADJOBSTATUS_URL = API_URL + "/api/jobstatuses/activestatuses";

//analytics dashboard
export const DASHBOARD_DATA_URL = API_URL + "/api/commons/dashboard/data";
// Added this code for  POW-102. Khushi. May 03 2023.. Code Start /
//Automation Dashboard
export const AUTOMATION_DATA_URL = API_URL+ "/api/commons/dashboard/automation/data";
// Khushi. May 03 2023. Code End /
//dashboard expenses setup
export const GET_CLIENT_LIST_URL = API_URL + "/api/job/expenses/getClientList";
export const ADD_CLIENT_LIST_URL = API_URL + "/api/job/expenses/addClientFee";
export const GET_CLIENT_LIST_DATA_URL =
  API_URL + "/api/job/expenses/searchClient";
export const UPDATE_CLIENT_LIST_DATA_URL =
  API_URL + "/api/job/expenses/updateClientFee";

//Insurances  setup
export const ADD_INSURANCES_DATA_URL =
  API_URL + "/api/job/expenses/addInsurancesFactoring";
export const UPDATE_INSURANCES_DATA_URL =
  API_URL + "/api/job/expenses/updateInsurancesFactoring";
export const GET_INSURANCES_DATA_URL =
  API_URL + "/api/job/expenses/getInsurancesFactoring";

//VMS  setup
export const GET_VMS_CLIENT_DATA_URL =
  API_URL + "/api/job/expenses/getVmsFeeClientList";
export const GET_VMS_DATA_URL = API_URL + "/api/job/expenses/getVmsFeeList";
export const GET_VMS_Table_DATA_URL =
  API_URL + "/api/job/expenses/searchVmsFee";
export const ADD_VMS_Table_DATA_URL = API_URL + "/api/job/expenses/addVmsFee";
export const UPDATE_Table_DATA_URL = API_URL + "/api/job/expenses/updateVmsFee";

//Job Board  setup
export const ADD_JOB_BOARD_DATA_URL = API_URL + "/api/job/expenses/addJobBoard";
export const DELETE_JOB_BOARD_DATA_URL =
  API_URL + "/api/job/expenses/deleteJobBoardById";
export const UPDATE_JOB_BOARD_DATA_URL =
  API_URL + "/api/job/expenses/updateJobBoard";
export const GET_JOB_BOARD_DATA_URL =
  API_URL + "/api/job/expenses/getAllJobBoard";

//

//Dashboard Job Data//

export const GETALLDATA = API_URL + "/api/commons/dashboard/data";
export const GETALLJOBUNATTENDED =
API_URL + "/api/commons/dashboard/getAllJobUnattended";
export const GETALLJOBSCLIENTTOTALS =
API_URL + "/api/commons/dashboard/getAllJobsClientsTotals";

export const GETRECRUITERSUBMISSIONS =
API_URL + "/api/commons/dashboard/getRecruiterSubmissions"; 

export const ASSIGNEDRECRUITERSJOBS =
API_URL + "/api/commons/dashboard/assignedRecruitersJobs";



// Automatic dahboard Run Log Table Data
export const RUNLOGDETAILS_URL = API_URL + "/api/automation/getDetails";